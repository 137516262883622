import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Navbar from "./components/navbar";
import Home from "./pages/Home";
function App() {
  return (
    <Router>
      <Navbar />
      {/* <main> */}
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      {/* </main> */}
    </Router>
  );
}

export default App;
