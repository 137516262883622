import React, { useState } from 'react';
import tab1Image from "../../assets/Images/tab1-image.png"
import tab2Image from "../../assets/Images/tab2-image.png"
import tab3Image from "../../assets/Images/tab3-image.png"
import tab4Image from "../../assets/Images/tab4-image.png"
const MakeHiringEasyComp = () => {
  const [selectedTag, setSelectedTag] = useState('Smart Assessments'); // Default selected tag

  // Data for tags and corresponding images
  const data = [
    {
      tag: 'Smart Assessments',
      description: 'We conduct campus drives and evaluate candidates with reliable skill-based assessments.',
      image: tab1Image,
      bgColor: '#F1F5FF',
    },
    {
      tag: 'Post Jobs with Ease',
      description: 'Select colleges, post your job openings, and create engaging exercises to connect with talent.',
      image: tab2Image,
      bgColor: '#EFECFF',
    },
    {
      tag: 'Skill-Based Filtering',
      description: 'Use verified skill scores to shortlist candidates who are the best fit for your roles.',
      image: tab3Image,
      bgColor: '#F4F2FF',
    },
    {
      tag: 'Focus on the Final Call',
      description: 'Work directly with pre-screened candidates, saving time and effort in the hiring process.',
      image: tab4Image,
      bgColor: '#FCECFC',
    },
  ];

  return (
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      {/* Heading */}
      <div className="text-center mb-8">
        <h2 className="common-header">How We Make Hiring Easy for You</h2>
        <p className="p-tag">
          Skip repetitive campus drives—we handle assessments and provide verified skill scores.
        </p>
      </div>

      {/* Content */}
      <div className='flex justify-center'>
      <div className="width-grid">
      <div className=" flex">
        {/* Left Side: Clickable Tags */}
        <div className='w-full md:w-[45%]'>
          {data.map((item) => (
            <div
              key={item.tag}
              onClick={() => setSelectedTag(item.tag)}
              className={`cursor-pointer p-4  transition-colors text-start ${
                selectedTag === item.tag
                  ? 'bg-purple-100'
                  : 'bg-gray-50 text-gray-800 hover:bg-gray-100'
              }`}
            >
              <h3 className="mini-header">{item.tag}</h3>
              <p className="paragraph-p">{item.description}</p>
            </div>
          ))}
        </div>

        {/* Right Side: Image Display */}
        <div className={`flex items-center justify-center p-5  w-[55%] rounded-md hidden md:flex ${
              data.find((item) => item.tag === selectedTag)?.bgColor
            }`}
            style={{
              backgroundColor: data.find((item) => item.tag === selectedTag)?.bgColor,
            }}>
          {data.map((item) => (
            selectedTag === item.tag && (
              <img
                key={item.tag}
                src={item.image}
                alt={item.tag}
                className="max-w-[100%] p-5 rounded-md"
              />
            )
          ))}
        </div>
      </div>
      </div>
      </div>
    </div>
  );
};

export default MakeHiringEasyComp;
