import React from 'react';
import hirewithconfidence from "../../assets/Images/hirewithconfidence.png";
import savetime from "../../assets/Images/savetime.png";
import seamlesshiring from "../../assets/Images/seamlesshiring.png";

const HomePageHighLights = () => {
    
    return (
        <section className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
            <div className="  mx-auto px-12 md:px-20 xl:px-28">
                <div className="title mb-11">
                    <h2 className="style_title" style={{ display: 'block' }}>
                        Don’t Stop There—Take Your Hiring to the Next Level"
                    </h2>
                </div>
                <div className="highlight-row my-16">
                    <div className="box lg:flex items-center">
                        <div className="images mb-8 lg:mb-0">
                            <img src={savetime} alt="AI-Powered Learning" className='w-[550px] rounded-lg' />
                        </div>
                        <div className="content-box text-left pl-5 lg:pl-8">
                            {/* <div className="benefits">
      <p className="text-lg tracking-[4px] text-purple-900">BENEFITS</p>
    </div> */}
                            <div className="content-title">
                                <h3 className='highlights-header'>Build Stronger Brand Engagement</h3>
                                <h4 className='highlights-miniheader'>Stay connected with the candidate pool like never before.</h4>
                            </div>
                            <div className="content">
                                <p className='paragraph-p'>We help you host competitions, sponsor student learnings, create mentorship programs, and empower students to grow and thrive. Position your brand as a key contributor to their success.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="highlight-row my-16">
                    <div className="box flex flex-col-reverse lg:flex-row gap-y-8 lg:gap-y-0 items-center">

                        <div className="content-box text-left pr-5 lg:pr-8">
                            {/* <div className="benefits">
      <p className="text-lg tracking-[4px] text-purple-900">BENEFITS</p>
    </div> */}
                            <div className="content-title">
                                <h3 className='highlights-header'>Leverage Advanced Analytics</h3>
                                <h4 className='highlights-miniheader'>Make smarter hiring decisions with deeper candidate insights.</h4>
                            </div>
                            <div className="content">
                                <p className='paragraph-p'>Use our candidate profiling tool to uncover strengths and gaps. Utilize AI interviews to evaluate technical and cultural fit to assist you in making the right call.</p>
                            </div>
                        </div>
                        <div className="images">
                            <img src={hirewithconfidence} alt="AI-Powered Learning" className='w-[550px] rounded-lg' />
                        </div>
                    </div>
                </div>
                <div className="highlight-row my-16">
                    <div className="box lg:flex items-center">
                        <div className="images mb-8 lg:mb-0">
                            <img src={seamlesshiring} alt="AI-Powered Learning" className='w-[550px] rounded-lg' />
                        </div>
                        <div className="content-box text-left pl-5 lg:pl-8">
                            {/* <div className="benefits">
      <p className="text-lg tracking-[4px] text-purple-900">BENEFITS</p>
    </div> */}
                            <div className="content-title">
                                <h3 className='highlights-header'>Automate Candidate Filtering</h3>
                                <h4 className='highlights-miniheader'>Simplify hiring with data-driven decisions powered by AI.</h4>
                            </div>
                            <div className="content">
                                <p className='paragraph-p'>Our AI-based profile mapper analyzes historical data and success metrics to automatically shortlist the best candidates, saving your team time and effort.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <p className="campus">
                        Campus hiring has never been this simple{' '}
                        <span className="font-semibold text-black">- Hire for free</span>
                    </p>
                    <button className="bg-purple-700 text-white hover:bg-purple-800 px-4 py-2 rounded-md mt-3">
                        <span>Get Started FREE</span>
                    </button>
            </div>
        </section>
    )
}
export default HomePageHighLights;


