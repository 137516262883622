import React from 'react';
import InitialComp from '../components/homeComponents/initialComp';
import UseOutProfiledComp from '../components/homeComponents/useOutProfiledComp';
import MakeHiringEasyComp from '../components/homeComponents/makeHiringEasyComp';
import HomePageHighLights from '../components/homeComponents/homePageHighlights';
import Footer from '../components/homeComponents/footer';


const Home = () => {
  return (
    <>
    <InitialComp />
    <UseOutProfiledComp />
    <MakeHiringEasyComp />
    <HomePageHighLights/>
    <Footer />
    </>
  )
}
export default Home;
