import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import MenuIcon from "../assets/Images/hamburger.png";
import CloseIcon from "../assets/Images/hamburger.png";
import mainLogo from "../assets/Images/mainLogo.png"

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <nav className="bg-white shadow-md fixed top-0 left-0 w-full z-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex items-center  justify-between h-16">
        <div className='flex items-center'>
          {/* Logo (with Mobile Icon) */}
          <img
            src={mobileMenuOpen ? CloseIcon : MenuIcon}
            alt={mobileMenuOpen ? "Close Menu" : "Menu"}
            className="h-6 w-6 lg:hidden cursor-pointer absolute right-14"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          />

          {/* Logo */}
          <a href="/" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}> {/* Wrap the logo in a link to the homepage */}
            <img src={mainLogo} alt="Your Logo" className="h-5 md:h-6 w-auto ml-4 md:ml-0" />
          </a>
        </div>
        <div className='flex items-center justify-between'>
          {/* Links */}
          <div className="hidden md:flex space-x-8">
            <NavLink to="/" className={({ isActive }) =>
              `text-sm ${isActive
                ? 'text-[#7F5BCC] font-semibold'
                : 'text-[#303030] hover:text-purple-700'
              }`
            } >
              Home
            </NavLink>
            <NavLink
             //to="/pricing"
             className={({ isActive }) =>
              `text-sm ${isActive
                ? 'text-[#7F5BCC] font-semibold'
                : 'text-[#303030] hover:text-purple-700'
              }`
            }
            >
              Pricing
            </NavLink>
            <NavLink
             //to="/students" 
             className={({ isActive }) =>
              `text-sm ${isActive
                ? 'text-[#7F5BCC] font-semibold'
                : 'text-[#303030] hover:text-purple-700'
              }`
            }>
              For students
            </NavLink>
          </div>
          {/* Buttons */}
          <div className="hidden lg:flex space-x-4">
            <a
             href="https://outprofiled.com/user/signup"
             target="_blank" 
              className="border border-purple-700 text-purple-700 px-4 py-2 rounded-md ml-4"
            >
              Get started FREE
            </a>
            <a
              href="https://outprofiled.com/user/login"
              target="_blank" 
              className="bg-purple-700 text-white hover:bg-purple-800 px-4 py-2 rounded-md w-32"
            >
              Login
            </a>
          </div>
        </div>
      </div>
      {/* Mobile Menu (Small Screen) */}
      {mobileMenuOpen && (
        <div className="lg:hidden absolute top-full inset-x-0 h-[100vh] bg-white p-4 rounded-md shadow-lg z-20 transition-transform duration-300 transform origin-top">
          <div className='flex flex-col'>
          <NavLink to="/" className={({ isActive }) =>
            `text-sm ${isActive
              ? 'text-[#7F5BCC] font-semibold'
              : 'text-[#303030] hover:text-purple-700'
            }`
          } >
            Home
          </NavLink>
          <NavLink to="/pricing" className={({ isActive }) =>
            `text-sm ${isActive
              ? 'text-[#7F5BCC] font-semibold'
              : 'text-[#303030] hover:text-purple-700'
            }`
          }
          >
            Pricing
          </NavLink>
          <NavLink
           //to="/students"
            className={({ isActive }) =>
            `text-sm ${isActive
              ? 'text-[#7F5BCC] font-semibold'
              : 'text-[#303030] hover:text-purple-700'
            }`
          }>
            For students
          </NavLink>
          {/* Buttons */}
          <div className="flex justify-center space-x-4">
            <a
              //href="/get-started"
              className="border border-purple-700 text-purple-700 px-4 py-2 rounded-md ml-4"
            >
              Get started FREE
            </a>
            <a
              href="/login"
              className="bg-purple-700 text-white hover:bg-purple-800 px-4 py-2 rounded-md w-32"
            >
              Login
            </a>
          </div>
          </div>
        </div>
      )}
    </nav>
  );
};
export default Navbar;
