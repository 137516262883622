import React from 'react';
import { ToastContainer } from 'react-toastify';
import mainImage from "../../assets/Images/main_img_home.png"
const InitialComp = () => {

    return (
        <div>
            {/* hero section */}
            <section className="bg-back-purple py-20 sm:pt-24 px-4 md:pr-0 md:pl-8 lg:pl-32  pb-0 pr-0 relative">
            {/* <section className="bg-back-purple  py-20 sm:py-24 px-4 md:px-8 lg:px-32 relative">  */}
                <div className=" mx-auto">
                    <div className="relative flex xl:justify-center flex-col-reverse xl:flex-row items-center rounded-lg gap-6  overflow-hidden ">
                        <div className="lg:text-left w-[60%] text-center">
                            <h1 className="banner-title text-2xl text-center lg:text-left lg:text-4xl font-bold ">Campus Hiring solution</h1>
                            <h3 className="sub-title">for hassle-free, skills-first hiring</h3>
                            <p className='p-tag mt-8'>Say goodbye to the time-intensive process of organizing campus drives by leveraging our pool of pre-assessed, qualified graduates. Free up your resources to focus on crafting a strong employer brand that draws the best and brightest to your company.</p>
                            <div className='mt-14'>
                            <button
                               className="bg-purple-700 text-white hover:bg-purple-800 px-4 py-2 rounded-md"
                            >
                                <span>Get Started FREE</span>
                            </button>
                            <button
                              className="border border-purple-700 text-purple-700 hover:border-purple-500 hover:text-purple-700 px-4 py-2 rounded-md mt-5 md:mt-0 md:ml-10"
                            // Call the click handler
                            >
                                <span>View plans and features</span>
                            </button>
                        </div>
                            <ToastContainer />
                        </div>
                        <div className="image">
                            <img src={mainImage} alt="AI-Powered Learning" className=' lg:w-[1000px] w-full h-auto object-contain pt-6' />
                        </div>
                    </div>

                    {/* <div className="mt-10">
            <SkillIcons />
          </div> */}
                </div>
                {/* <div className="circle p">
          <a href="#" className="box p-8 rounded-full bg-[#4E489B] absolute right-2/4 translate-x-2/4 -bottom-11">
            <FaPlay className='text-3xl text-white pl-1' />

            <div className="box absolute top-[2px] left-[2px] text-white">
              <CurvedText />
            </div>
          </a>
        </div> */}
            </section>
        </div>
    )
}
export default InitialComp;
